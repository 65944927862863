import React from "react"
import { graphql } from "gatsby"
import {
    Section,
    Title,
    Column,
    Content,
    Columns
  } from "bloomer"
  
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import AnjosForm from "../components/anjosForm"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  let anjoImage = frontmatter.image.childImageSharp.fluid
  return (
    <Layout>
      
    <SEO title={`${frontmatter.name} Anjo Protetor  - Estrelaguia Guia`} /> 
            <Columns>
                <Column isSize="1/3">
                  <Img fluid={anjoImage} />
                </Column>
                <Column>
                <Title>{frontmatter.name}</Title>
                
                {/* <Subtitle>Descripção</Subtitle> */}
                <Content dangerouslySetInnerHTML={{ __html: html }}/>
                <ul>
                    <li><strong>Salmo: </strong>{frontmatter.psalm}</li>
                    <li><strong>Cor: </strong>{frontmatter.color}</li>
                    <li><strong>Aroma: </strong>{frontmatter.aroma}</li>
                    <li><strong>Pedra: </strong>{frontmatter.stone}</li>
                    <li><strong>Hora: </strong>{frontmatter.hour}</li>
                    <li><strong>Dia: </strong>{frontmatter.day}</li>
                </ul>
                
                </Column>
                
            </Columns>
            <Content>
            <h1>Os Anjos</h1>
            <h2>Seu Anjo Protetor</h2>
            <p>O Anjo é uma criatura celestial, um ser intermediário entre Deus e os homens. Tem a função de ajudante ou mensageiro de Deus. Aparecem mais de 300 vezes na Bíblia, usando nomes como Santos, heróis e filhos de Deus. Os anjos são enviados divinos que protegem e guiam os seres humanos.</p>
            <p>Eles são próximos aos homens, presentes nas tradições de diversas civilizações (gregos, romanos, . São feitos de luz e podem estar em vários lugares ao mesmo tempo e podem assumir formas humanas. Acredita-se que se comunicam conosco mandando vibrações e por meio dos nossos sonhos.</p>
            <p>O seu anjo da guarda te acompanha, é o seu guardião. Mas não interferem no seu livre arbítrio. É você quem toma as suas decisões e é o responsável por estas.</p>
            <p>Invocá-los com o bem em mente pode contribuir para que a pessoa tenha um interesse mais profundo pelo próximo. Pode ajudá-la a ter luz espiritual por meio do aperfeiçoamento de seus atos e pensamentos. A ser uma pessoa com propósitos positivos.</p>
            <h2>Descubra seu Anjo Protetor</h2>
            <AnjosForm></AnjosForm>
        </Content>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        stone
        slug
        psalm
        name
        image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        hour
        day
        color
        aroma
        angel_id
      }
      html
    }
  }
`;